import React, {useState} from 'react';
import useHttp from '../../hooks/useHttp';
import {withRouter} from 'react-router-dom';
import './Login.scss';
import ErrorNotification from '../Notifications/ErrorNotification/ErrorNotification';
import Button from '../Button/Button';
import {useInput} from '../../hooks/useInput';
import SuccessNotification from "../Notifications/SucessNotification/SuccessNotification";
import {authServiceUrl} from "../../config/apiConfig";
import {hideLoader, showLoader} from "../../helpers/loaderHelpers";

const Login = (props) => {
    const { value:username, bind:bindUsername, reset:resetUsername } = useInput('');
    const { value:password, bind:bindPassword, reset:resetPassword } = useInput('');
    const {
        handleRequest,
    } = useHttp();

    const [error, setError] = useState('');

    const resetInputs = () => {
        resetUsername();
        resetPassword();
    };

    const loginUser = async (e) => {
        e.preventDefault();
        resetInputs();
        try {
            showLoader();
            const data = await handleRequest('POST', 'login', {username, password}, authServiceUrl);
            sessionStorage.setItem('sessionToken', data.token);
            window.dispatchEvent(new CustomEvent('login', {detail: {login: true}}));
            hideLoader();
            props.history.push('/');
        } catch (e) {
            setError(e.message);
            hideLoader();
        }
    }

    return (
        <div className="login">
            <h1>Login</h1>
            <div>
                {error && <ErrorNotification errorMessage={error}/>}
                {props.history.location.state && props.history.location.state.successMessage &&
                    <SuccessNotification successMessage={props.history.location.state.successMessage}/>}
                <input type='text' placeholder='Username' {...bindUsername}/>
                <input type='password' placeholder='Password' {...bindPassword}/>
                <p><a href="/forgot-password">Forgot your password?</a></p>
                <Button text='Let me in.' action={loginUser}/>
            </div>
        </div>
    );
}

export default withRouter(Login);