import React from 'react';
import './Button.scss'

const Button = (props) => {
    const {action, text, testId} = props;

    return (
        <button className="btn btnLarge btnPrimary btnBlock" onClick={action}
                data-testid={testId || 'button'}>{text}</button>
    )
};

export default Button;