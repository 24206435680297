import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFolder, faFile, faFileAlt, faArrowAltCircleLeft, faWindowClose } from '@fortawesome/free-regular-svg-icons';
import { faFolderPlus, faPen, faFile as file } from '@fortawesome/free-solid-svg-icons';
import Layout from './components/Layout/Layout';
import Login from './components/Login/Login';
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import Loader from './components/Loader/Loader';
import FolderTree from "./components/FolderTree/FolderTree";
import ContentEditor from "./components/ContentEditor/ContentEditor";
import DiffComparison from "./components/DiffComparison/DiffComparison";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import ScrapeManager from "./components/ScrapeManager/ScrapeManager";

library.add(faFolder, faFileAlt, faFile, faFolderPlus, file, faArrowAltCircleLeft, faWindowClose, faPen);

const App = () => {
    return (
        <Router>
            <div className='App'>
                <Layout>
                    <Loader />
                    <Switch>
                        <Route path='/login'>
                            <Login />
                        </Route>
                        <Route path='/forgot-password'>
                            <ForgotPassword />
                        </Route>
                        <Route path='/reset-password'>
                            <ResetPassword />
                        </Route>
                        <PrivateRoute path='/content-editor'>
                            <ContentEditor />
                        </PrivateRoute>
                        <PrivateRoute path='/diff-comparison'>
                            <DiffComparison />
                        </PrivateRoute>
                        <PrivateRoute path='/manage'>
                            <ScrapeManager />
                        </PrivateRoute>
                        <PrivateRoute path='/'>
                            <FolderTree />
                        </PrivateRoute>
                    </Switch>
                </Layout>
            </div>
        </Router>
    )
}

export default App;