import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {useState, useEffect, Fragment} from "react";
import {useInput} from "../../hooks/useInput";
import Button from "../Button/Button";
import "./ScrapeManager.scss";
import useHttp from "../../hooks/useHttp";
import {adScrapeServiceUrl} from "../../config/apiConfig";
import {hideLoader, showLoader} from "../../helpers/loaderHelpers";

const ScrapeManager = () => {
    const {handleRequest} = useHttp();
    
    useEffect(() => {
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const modalState = {
        UPDATE: "UPDATE",
        CREATE: "CREATE"
    };

    const [editingItem, setEditingItem] = useState({});
    const [renderData, setRenderData] = useState([]);
    const [modalMethod, setModalMethod] = useState("UPDATE")
    const [showModal, setShowModal] = useState(false);
    const { value:domain, bind:bindDomain, setValue: setDomainValue, reset:resetDomain } = useInput('');
    const { value:accountId, bind:bindAccountId, setValue: setAccountIdValue, reset:resetAccountId } = useInput('');
    const { value:accountType, bind:bindAccountType, setValue: setAccountTypeValue, reset:resetAccountType } = useInput('');

    const columns = [
        {
            name: 'Domain',
            selector: row => row.domain,
        },
        {
            name: 'Account ID',
            selector: row => row.publisher_account_id,
        },
        {
            name: 'Account Type',
            selector: row => row.account_type,
        },
        {
            name: 'Edit',
            selector: row => row.editLink,
        },
        {
            name: 'Delete',
            selector: row => row.deleteLink,
        },
    ];

    const getData = async () => {
        showLoader();
        const data = await handleRequest('GET', 'get', null, adScrapeServiceUrl);
        setRenderData(data.data.map(item => {
            item.editLink = <FontAwesomeIcon onClick={() => {openModalForEdit(item)}} icon={["fas", "pen"]} title="add folder" />
            item.deleteLink = <FontAwesomeIcon onClick={() => {deleteItem(item)}} icon={["far", "window-close"]} title="add folder" />
            return item;
        }));
        hideLoader();
    };
    
    const openModalForEdit = (item) => {
        setEditingItem(item);
        setModalMethod(modalState.UPDATE);
        setDomainValue(item.domain);
        setAccountTypeValue(item.account_type);
        setAccountIdValue(item.publisher_account_id);
        setShowModal(true);
    }

    const openModalForAddingItems = () => {
        setModalMethod(modalState.CREATE);
        setShowModal(true);
    }
    
    const update = async () => {
        showLoader();
        const updatedItem = {
            id: editingItem.id,
            domain,
            publisher_account_id: accountId,
            account_type: accountType,
        };
        const data = await handleRequest('PATCH', 'update', {data: [updatedItem]}, adScrapeServiceUrl);
        if (data.status !== "error") {
            await getData();
            closeModal();
        }
    }

    const create = async () => {
        showLoader();
        const newItem = {
            domain,
            publisher_account_id: accountId,
            account_type: accountType,
        };
        const data = await handleRequest('POST', 'add', {data: [newItem]}, adScrapeServiceUrl);
        if (data.status !== "error") {
            await getData();
            closeModal();
        }
        hideLoader();
    }

    const deleteItem = async (item) => {
        if (window.confirm(`Are you sure that you want to delete the item ${item.domain}`)) {
            showLoader();
            const data = await handleRequest('DELETE', 'delete', {data: [item.id]}, adScrapeServiceUrl);
            if (data.status !== "error") {
                await getData();
            }
        }
    }

    const closeModal = () => {
        resetDomain();
        resetAccountId();
        resetAccountType();
        setShowModal(false);
    }

    return (
        <div className="container">
            {showModal && <div className="modalHolder">
                <div className="modal">
                    <div className="innerModal">
                        <div><span>Domain: </span><input type='text' {...bindDomain}/></div>
                        <div><span>Account ID: </span><input type='text' {...bindAccountId}/></div>
                        <div><span>Account Type: </span><input type='text' {...bindAccountType}/></div>
                        <div className="buttonHolder">
                            <Button action={modalMethod === modalState.UPDATE ? update : create} text={modalMethod === modalState.UPDATE ? "update" : "create"}/>
                            <Button action={closeModal} text="close"/>
                        </div>
                    </div>
                </div>
            </div>}
            <div className="scrapeManager">
                {
                    renderData.length > 0 && <Fragment>
                        <div className="tableHolder">
                            <DataTable columns={columns} data={renderData} pagination/>
                        </div>
                        <Button text="Add new" action={openModalForAddingItems}/>
                    </Fragment>
                }

            </div>
        </div>
    )
}

export default ScrapeManager;