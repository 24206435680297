import {withRouter} from 'react-router-dom';
import useHttp from '../../hooks/useHttp';
import './DiffComparison.scss';
import {useState} from "react";
import Button from "../Button/Button";
import SuccessNotification from "../Notifications/SucessNotification/SuccessNotification";
import {apiUrl} from "../../config/apiConfig";

const DiffComparison = (props) => {
    const {contentDiff, originalContent, textContent, fileName, path, breadcrumbs} = props.history.location.state;
    const [changeSymbols] = useState({
        added: '+',
        removed: '-'
    });
    const [successMessage, setSuccessMessage] = useState('');

    const {
        handleRequest
    } = useHttp();

    const handleCommit = async () => {
        const commitMessage = window.prompt('Enter the commit message:')
        if(commitMessage === '' || commitMessage.length <= 3) {
            alert('Commit message must not be left empty and must be longer then 3 characters!');
        } else if(commitMessage) {
            const fullPath = path + fileName;
            const data = await handleRequest('POST', 'file', {
                commitMessage,
                content: textContent,
                filePath: fullPath.split('').slice(2, fullPath.length).join('') //removing the ./ prefix from the full path
            }, apiUrl);
            setSuccessMessage('Commit successful!');
            if(data) {
                setTimeout(() => {
                    props.history.push('/', {
                        folderSha: data.treeSha,
                        breadcrumbsArray: data.breadcrumbs
                    });
                }, 1000);
            }
        }
    };

    const handleBackButton = () => {
        props.history.push('/content-editor', {
            editedContent: textContent,
            fileContent: originalContent,
            fileName,
            path,
            breadcrumbs
        });
    }

    return (
        <div className="diffComparisonHolder">
            <SuccessNotification successMessage={successMessage}/>
            <h2>{path + fileName}</h2>
            {Array.isArray(contentDiff) && contentDiff.length && <div className="diffHolder">
                <ul className="diffList">
                    {contentDiff.map((item, index) =>
                        <li key={index}>
                            {Array.isArray(item) ?
                                <div className={item[0]}>
                                    {`${changeSymbols[item[0]]}  ${item[1]}`}
                                </div> :
                                <div className={item ? 'unchanged' : 'spacing'}>{item || 'line-break'}</div>}
                        </li>)}
                </ul>
            </div>}
            <div className="buttonHolder">
                <Button action={handleBackButton} text="Back"/>
                <Button action={handleCommit} text="Commit" testId="commitButton"/>
            </div>
        </div>
    )
}

export default withRouter(DiffComparison);