import {Fragment, useEffect, useState} from 'react';
import './Loader.scss';

const Loader = () => {
    const [showLoader, setShowLoader] = useState(false);

    const handleEvent = (event) => {
        if(event.detail.loaderShow !== undefined) {
            setShowLoader(event.detail.loaderShow);
        }
    }

    useEffect(() => {
        window.addEventListener('loader', handleEvent);

        return () => {
            window.removeEventListener('loader', handleEvent);
        }
    },[]);

    return <Fragment>
        {showLoader && (
            <div className="loader">
                <div className="loading">
                    Loading&#8230;
                </div>
            </div>
        )}
    </Fragment>
};

export default Loader;