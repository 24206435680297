import React, {useState} from 'react';
import useHttp from '../../hooks/useHttp';
import {withRouter} from 'react-router-dom';
import './ResetPassword.scss';
import Button from '../Button/Button';
import {useInput} from '../../hooks/useInput';
import ErrorNotification from "../Notifications/ErrorNotification/ErrorNotification";
import {authServiceUrl} from "../../config/apiConfig";
import {hideLoader, showLoader} from "../../helpers/loaderHelpers";

const ResetPassword = (props) => {
    let username = '';
    try {
        username = props.history.location.state.username;
    } catch (e) {
        props.history.push('/');
    }
    const [error, setError] = useState('');
    const { value:password, bind:bindPassword, reset:resetPassword } = useInput('');
    const { value:confirmPassword, bind:bindConfirmPassword, reset:resetConfirmPassword } = useInput('');
    const { value:verificationCode, bind:bindVerificationCode} = useInput('');
    const {
        handleRequest
    } = useHttp();

    const resetInputs = () => {
        resetPassword();
        resetConfirmPassword();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!password || password.localeCompare(confirmPassword) !== 0) {
            setError('passwords don\'t match' );
            resetInputs();
        } else if(!verificationCode) {
            setError('missing verification code' );
        }
        else {
            try {
                showLoader();
                await handleRequest('POST', 'password/reset', {username, verificationCode, newPassword: password}, authServiceUrl);
                props.history.push('/login', {
                    successMessage: 'password successfully changed'
                });
            } catch (e) {
                setError(e.message);
                hideLoader();
            }
        }
    }

    return (
        <div className="resetPassword">
            <h1>Reset password for {username}?</h1>
            <div>
                {error && <ErrorNotification errorMessage={error}/>}
                <input type='text' placeholder='Code, sent to your email' {...bindVerificationCode}/>
                <input type='password' placeholder='Password' {...bindPassword}/>
                <input type='password' placeholder='Confirm Password' {...bindConfirmPassword}/>
                <Button text='Submit' action={handleSubmit}/>
            </div>
        </div>
    );
}

export default withRouter(ResetPassword);