import {useEffect, useState} from 'react';
import './Menu.scss';
import MenuLink from "../MenuLink/MenuLink";

const Menu = () => {
    const [token, setToken] = useState('');

    const handleEvent = (event) => {
        if(event.detail.login !== undefined) {
            setToken(sessionStorage.getItem('sessionToken'));
        }
    }

    useEffect(() => {
        window.addEventListener('login', handleEvent);
        setToken(sessionStorage.getItem('sessionToken'));
    }, []);

    const logoutAction = () => {
        sessionStorage.removeItem('sessionToken');
        setToken('');
        window.location.href = '/login';
    }

    return token && <div className="menu">
        <MenuLink to="/" label="Home" />
        <MenuLink to="/manage" label="Monitoring" />
        <div className="logout" onClick={logoutAction} data-testid="logout">
            <span>Logout</span>
        </div>
    </div>
}

export default Menu;