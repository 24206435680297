const apiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV :
    process.env.REACT_APP_API_URL_PRODUCTION;

const authServiceUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_AUTH_SERVICE_URL_DEV :
    process.env.REACT_APP_AUTH_SERVICE_URL_PROD;

const adScrapeServiceUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_AD_SCRAPE_SERVICE_DEV :
    process.env.REACT_APP_AD_SCRAPE_SERVICE_PROD;

const hostDomain = process.env.REACT_APP_HOST_DOMAIN;

export {apiUrl, authServiceUrl, hostDomain, adScrapeServiceUrl};