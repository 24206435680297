import {Fragment} from 'react';
import './Layout.scss';
import Menu from "../Menu/Menu";

const layout = (props) => (
    <Fragment>
        <header>
            <div className="left"><a href="/">Ads.txt Panel</a></div>
            <div className="right">
                <div className="menu">
                    <Menu />
                </div>
            </div>
        </header>
        <main>
            {props.children}
        </main>
    </Fragment>
);

export default layout;