import { useCallback } from 'react';
import {authServiceUrl} from '../config/apiConfig';
import {showLoader} from "../helpers/loaderHelpers";

const handleFetchError = async (response) => {
    const data = await response.json();
    showLoader();
    if (response.status === 401 && data.message !== 'Bad credentials!') {
        sessionStorage.removeItem('sessionToken');
        window.location.href = '/';
    }
    throw new Error(data.errorDetails.message);
}

const useHttp = () => {

    const handleFetchResponse = useCallback(async (response) => {
        if (response.status === 404) {
            return null;
        }
        else if (!response.ok) {
            await handleFetchError(response);
        }
        return await response.json();
    }, []);

    const handleRequest = useCallback(async (method, path, data = null, serviceUrl) => {
        const headers = {
            "Content-Type": "application/json"
        };
        if (serviceUrl !== authServiceUrl) {
            headers['Authorization'] = sessionStorage.getItem('sessionToken')
        }

        const requestOptions = {
            headers,
            method
        }
        if(data) {
            requestOptions.body = JSON.stringify(data)
        }

        const response = await fetch(`${serviceUrl}/${path}`, requestOptions);
        return handleFetchResponse(response);
    }, [handleFetchResponse]);

    return {
        handleRequest,
    }
}

export default useHttp;