import React from 'react';
import useHttp from '../../hooks/useHttp';
import {withRouter} from 'react-router-dom';
import './ForgotPassword.scss';
import Button from '../Button/Button';
import {useInput} from '../../hooks/useInput';
import {authServiceUrl} from "../../config/apiConfig";

const ForgotPassword = (props) => {
    const { value:username, bind:bindUsername } = useInput('');
    const {
        handleRequest,
    } = useHttp();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = await handleRequest('POST', 'password/forgot', {username}, authServiceUrl);
        if (data) {
            props.history.push('/reset-password', {
                username
            });
        }
    }

    return (
        <div className="forgotPassword">
            <h1>Forgot Password?</h1>
            <div>
                <input type='text' placeholder='Username' {...bindUsername}/>
                <Button text='Submit' action={handleSubmit}/>
            </div>
        </div>
    );
}

export default withRouter(ForgotPassword);