import React from "react";
import {
    Link,
    useRouteMatch
} from "react-router-dom";
import "./MenuLink.scss"

const MenuLink = ({ label, to }) => {
    let match = useRouteMatch({
        path: to,
        exact: true
    });

    return (
        <div className={match ? "menuLink active" : "menuLink"}>
            <Link to={to}>{label}</Link>
        </div>
    );
}

export default MenuLink;