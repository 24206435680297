import {useEffect, useState} from "react";
import Button from "../Button/Button";
import {withRouter} from 'react-router-dom';
import useHttp from "../../hooks/useHttp";
import './ContentEditor.scss';
import {apiUrl} from "../../config/apiConfig";

const ContentEditor = (props) => {
    const {fileContent, editedContent, fileName, path, breadcrumbs} = props.history.location.state;
    const [textContent, setTextContent] = useState('');

    const {
        handleRequest
    } = useHttp();

    // in case we go back from diff comparison without committing
    useEffect(() => {
        if (editedContent) setTextContent(editedContent);
    }, [editedContent]);

    const submitContent = async () => {
        if (textContent) {
            const data = await handleRequest('POST', 'file/diff', {fileContent: btoa(fileContent), textContent: btoa(textContent)}, apiUrl);
            if (data && data.fileDiff) {
                props.history.push('/diff-comparison', {
                    contentDiff: data.fileDiff,
                    textContent,
                    originalContent: fileContent,
                    fileName,
                    path,
                    breadcrumbs
                })
            }
        }
    }

    const handleTextChange = (event) => {
        setTextContent(event.target.value);
    }

    const handleBackClick = () => {
        if (window.confirm('If you go back all of you uncommitted changes will be lost!')) {
            const lastItem = breadcrumbs[breadcrumbs.length -1]
            props.history.push('/', {
                breadcrumbsArray: breadcrumbs,
                folderSha: lastItem.sha
            });
        }
    }

    return (
        <div className="contentEditor">
            <h2 data-testid="path">{path + fileName}</h2>
            <textarea defaultValue={editedContent || fileContent} onChange={handleTextChange}/>
            <div className="buttonHolder">
                <Button action={handleBackClick} text="Back" testId="backButton"/>
                <Button action={submitContent} text="Submit" testId="submitButton"/>
            </div>
        </div>
    )
}

export default withRouter(ContentEditor);