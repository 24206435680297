import {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import useHttp from '../../hooks/useHttp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './FolderTree.scss';
import {apiUrl, hostDomain} from "../../config/apiConfig";
import {hideLoader, showLoader} from "../../helpers/loaderHelpers";

const FolderTree = (props) => {
    const {
        handleRequest
    } = useHttp();

    const [tree, setTree] = useState([]);
    const [breadcrumbs, setBreadcrumbs] = useState([{path: './src'}]);
    const [currentPath, setCurrentPath] = useState('');

    useEffect(() => {
        const getInitialData = async () => {
            showLoader();
            let folderSha = '';
            if (props.history.location.state) {
                // checks to see if folder sha was sent after the successful commit
                folderSha = props.history.location.state.folderSha;

                /* if the commit was successful we will get breadcrumbs with updated sha for each folder
                in the structure which means breadcrumbs need to be re-built */
                const {breadcrumbsArray} = props.history.location.state;
                if (breadcrumbsArray && breadcrumbsArray.length) {
                    setBreadcrumbs(breadcrumbsArray);
                }
            }
            const data = await handleRequest('GET', folderSha ? `folder/${folderSha}` : 'folder', null, apiUrl);
            setTree(data.tree);
            hideLoader();
        }
        getInitialData();
        // cleanup after redirection
        return () => {
            setBreadcrumbs([{path: './src'}]);
            setCurrentPath('./src');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // gets triggered on each breadcrumb change and builds "path" string
    useEffect(() => {
        const localValue = breadcrumbs.reduce((acc, cur) => acc + cur.path + '/', '');
        setCurrentPath(localValue)
    }, [breadcrumbs]);

    /* we need to push breadcrumbs to content-editor in order to be able to easily return
    to previous folder tree and to generate folderPath and breadcrumbs back in folder tree */
    const redirectToContentEditor = (content, fileName) => {
        props.history.push('/content-editor', {
            fileContent: content,
            path: currentPath,
            fileName,
            breadcrumbs
        })
    }

    const handleBackButtonClick = async () => {
        if (breadcrumbs.length > 1) {
            const localBreadcrumbs = Object.assign([], breadcrumbs);
            if (
                // checks if the new folder was created but not committed and shows a prompt
                (!localBreadcrumbs[localBreadcrumbs.length - 1].sha && window.confirm('If you go back, you will loose your empty folder, is that ok?'))
                || localBreadcrumbs[localBreadcrumbs.length - 1].sha
            ) {
                localBreadcrumbs.pop();
                const item = localBreadcrumbs[localBreadcrumbs.length - 1];
                await getContent(item);
                setBreadcrumbs(localBreadcrumbs);
            }
        }
    }

    const getContent = async (item) => {
        let data;
        if (!item.sha) {
            data = await handleRequest('GET', 'folder', null, apiUrl);
            setTree(data.tree);
        }
        else {
            let path;
            if (item.type === 'blob') {
                path = `file/${item.sha}`;
                data = await handleRequest('GET', path, null, apiUrl);
                const content = atob(data.content);
                redirectToContentEditor(content, item.path);
            } else {
                path = `folder/${item.sha}`;
                data = await handleRequest('GET', path, null, apiUrl);
                setTree(data.tree);
            }
        }
    }

    const handleItemClick = async (item) => {
        await getContent(item);
        setBreadcrumbs((prevState) => {
            const newState = Object.assign([], prevState);
            newState.push(item);
            setBreadcrumbs(newState);
        });
    }

    const addFolder = () => {
        const folderName = prompt('Enter folder name:');
        if (folderName) {
            setBreadcrumbs((prevState) => {
                const newState = Object.assign([], prevState);
                newState.push({path: folderName});
                setBreadcrumbs(newState);
            });
            setTree([]);
        }
    }

    const addFile = () => {
        const fileName = prompt('Enter file name:');
        if (fileName) {
            setBreadcrumbs((prevState) => {
                const newState = Object.assign([], prevState);
                newState.push({path: fileName});
                setBreadcrumbs(newState);
            });
            props.history.push('/content-editor', {
                fileContent: '',
                path: currentPath,
                fileName
            })
        }
    }

    const getHostDomain = (fileName) => {
        const refFilePath = currentPath.split('./src')[1]; // removes ./src from the path
        return hostDomain + refFilePath + fileName;
    }

    return (
        <div className="treeContainer">
            <div className="breadcrumbs" data-testid="breadcrumbs">{currentPath}</div>
            <div className="controls">
                <div className="left">
                    {breadcrumbs && breadcrumbs.length > 1 && <FontAwesomeIcon onClick={handleBackButtonClick} title="back" icon={['far', 'arrow-alt-circle-left']} />}
                </div>
                <div className="right">
                    <FontAwesomeIcon onClick={addFolder} icon={["fas", "folder-plus"]} title="add folder" />
                    <FontAwesomeIcon onClick={addFile} icon={["fas", "file"]} title="add file"/>
                </div>
            </div>
            <ul className="folderList">
                {tree && Array.isArray(tree) && tree.map((row) => (
                    <li key={row.sha}>
                        <span onClick={async () => {await handleItemClick(row)}}>
                          {row.type === 'blob' ? <FontAwesomeIcon icon={["far", "file-alt"]} /> : <FontAwesomeIcon icon={["far", "folder"]} />}
                            <span className="itemName">{row.path}</span>
                        </span>
                        {row.type === 'blob' ? <span className="domainLink"><a href={getHostDomain(row.path)} target="_blank" rel="noreferrer">{getHostDomain(row.path)}</a></span> : ''}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default withRouter(FolderTree);